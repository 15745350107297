<template>
  <page-view title="详情" left-arrow>
    <div class="notify-doc container">
      <div class="notify-doc__header">
        <h2 class="notify-doc__title">{{xxbt}}</h2>
        <p class="notify-doc__time">
          <span style="margin-right: 0.5em">系统公告</span>
          <span>{{fbsj}}</span></p>
      </div>
      <div class="notify-doc__content" v-html="xxnr"></div>
    </div>
  </page-view>
</template>

<script>
import { hlwFbxx_selectiveGetOne } from '@/api/psdmsqxgl/qxglschema/HlwFbxxAPI'
export default {
  name: 'NotifyDetail',
  data() {
    return {
      xxbt:'',
      xxnr:'',
      fbr :'',
      xxly:'',
      fbsj:''
    }
  },
  methods: {
    fetchData(xxid) {
      hlwFbxx_selectiveGetOne({xxid: xxid}).then(res => {
        let data = res.content;
        this.xxbt = data.xxbt;
        this.xxnr = data.xxnr;
        this.fbr = data.fbr;
        this.fbsj = data.fbsj
      })
    }
  },
  mounted() {
    let params = this.$route.params;
    if (params._id) {
      this.xxid = params._id;
      this.fetchData(params._id)
    }
  },
}
</script>

<style lang="scss" scoped>
   @import 'src/styles/variable.scss';
  .notify-doc__header {
    margin-bottom: 20px;
  }
  .notify-doc__title {
    margin: 16px 0;
    line-height: 1.6;
    font-size: 16px;
  }

  .notify-doc__time {
    font-size: 12px;
    color: $color-gray-300;
  }
  .notify-doc__content /deep/{

    p {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 10px;
    }
  }
</style>