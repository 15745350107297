// WaGen___HASHKEY__9f238a30_2021-11-02 16:26:19 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
 互联网发布信息
 吴四九  2021/1/29

*/
/* ---
标准CRUD方法：添加记录
*/
export function hlwFbxx_add(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：xxid : Long
*/
export function hlwFbxx_update(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：xxid : Long
*/
export function hlwFbxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function hlwFbxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function hlwFbxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function hlwFbxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：xxid : Long
*/
export function hlwFbxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function hlwFbxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function hlwFbxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //HlwFbxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function hlwFbxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function hlwFbxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //HlwFbxx
		}
	})
}

/* ---
未提供注释
*/
export function hlwFbxx_getHlwFbxxid(meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/getHlwFbxxid',
		method : 'post',
		headers : {
		},
		data : {
		}
	})
}

/* ---
未提供注释
*/
export function hlwFbxx_getFbxxByJgbm(jgbm,xxlb,fbbzwxh,fbbzapp,fbbzyyt, pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/getFbxxByJgbm',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				xxlb : xxlb, //String
				fbbzwxh : fbbzwxh, //String
				fbbzapp : fbbzapp, //String
				fbbzyyt : fbbzyyt //String
			},
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function hlwFbxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<HlwFbxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：xxid : Long
*/
export function hlwFbxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<HlwFbxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：xxid : Long
*/
export function hlwFbxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsqxgl/qxglschema/HlwFbxx/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<HlwFbxx>
		}
	})
}

